.break a {
  margin: 5px;
}
.pagination {
  border-width: 1px;
  border-radius: 4px !important;
  padding: 0 !important;
  overflow: hidden;
}
.pageitem {
  width: 10px;
  height: 10px;
  background-color: var(--color-light) !important;
  border-width: 0.1px;
}
.page-item {
  width: 25px;
  height: 25px;
  border-width: 0.1px;
  background: var(--color-light);
  color: var(--color-secondary);
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.page-item .page-link {
  width: 110%;
  height: 110%;
  padding: 0;
  border-width: 0.1px;
  background: var(--color-light);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.page-item .page-link:hover {
  background: var(--color-secondary);
  color: var(--color-light);
}
.selected .page-link {
  background: var(--color-secondary) !important;
  color: var(--color-light);
  box-shadow: none;
}

.my-stars .react-stars .star {
  cursor: default;
  transition: none;
}

.my-stars .react-stars .star:hover,
.my-stars .react-stars .star:hover ~ .star {
  color: inherit !important;
}
