.glassy-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10vw;
  margin-right: 10vw;
  border-radius: 25px;
  overflow: visible;
  position: relative;
}

.glassy-container::before {
  content: "";
  position: absolute;
  top: -20px;
  left: -20px;
  right: -20px;
  bottom: -20px;
  background: linear-gradient(
    to bottom right,
    var(--color-secondary-60),
    rgba(255, 255, 255, 0.6)
  );
  filter: blur(50px);
  z-index: -1;
}

.glassy-frame {
  position: relative;
  border: 1px solid transparent;
  border-radius: 25px;
  padding: 20px;
  background: linear-gradient(
    to bottom right,
    var(--color-secondary-60),
    rgba(255, 255, 255, 0.6)
  );
  background-clip: padding-box;
  backdrop-filter: blur(100px);
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.glassy-frame::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 25px;
  padding: 2px;
  background: linear-gradient(to bottom right, var(--color-secondary), white);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  z-index: -1;
}

.content {
  position: relative;
  background: black;
  border-radius: 15px;
  padding: 16px;
}
/*  Stats Section - Container */
.stats-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

