@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-main: #deedf9;
  --color-secondary: #00CE2D;
  --color-third: #ffb1b1;
  --color-dark: #181818;
  --color-light: white;
  --main-transition: 0.3s;
}

@font-face {
  font-family: "Cairo";
  src: local("Cairo Regular"),
    url("../assets/fonts/Cairo-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cairo";
  src: local("Cairo Bold"),
    url("../assets/fonts/Cairo-Bold.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: "Cairo", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background-color: #deedf9;
  cursor: default;
  margin: 0;
  padding: 0;
}
* {
  scrollbar-width: thin;
  scrollbar-color: var(--color-secondary) var(--color-light);
}
.hoverMain::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0%;
  height: 100%;
  transition: 0.4s;
}
.hoverMain:hover::after {
  width: 100%;
}
.hoverMain {
  transition: 0.4s;
}
.hoverMain:hover {
  color: white !important;
}
input {
  outline: none;
  padding-left: 14px;
  padding-right: 14px;
  display: block;
  width: 100%;
}
textarea {
  resize: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

*::-webkit-scrollbar {
  width: 15px;
}
*::-webkit-scrollbar-track {
  background: var(--color-light);
}
*::-webkit-scrollbar-thumb {
  background-color: var(--color-secondary);
  border-radius: 14px;
  border: 3px solid var(--color-light);
}

ul li a.active,
ul li a.active:hover {
  color: white !important;
  background-color: var(--color-secondary);
  border-radius: 0.5rem;
}
.scroll {
  scrollbar-width: none !important;
}
.hoverMain::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0%;
  height: 100%;
  transition: 0.4s;
}
.hoverMain:hover::after {
  width: 100%;
}
.hoverMain {
  transition: 0.4s;
}
.hoverMain:hover {
  color: white !important;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}
.gradient-text {
  background-image: linear-gradient(to right, #3cebc1, #fbe200);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 45px !important;
  font-weight: bold !important;
}
.image-container {
  position: relative;
  overflow: hidden;
}

.image-container img {
  display: block;
  transition: filter 0.5s; /* تأثير التحول */
  filter: grayscale(100%); /* إضافة الفلتر عند الهوفرة */
}

.image-container img:hover {
  filter: grayscale(0%); /* إضافة الفلتر عند الهوفرة */
}
